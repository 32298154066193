import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as frTranslation from './translations/fr.json'
import * as enTranslation from './translations/en.json'
import * as deTranslation from './translations/de.json'
import * as nlTranslation from './translations/nl.json'
import * as spTranslation from './translations/es.json'
import HomePage from './pages/HomePage.page'
import Calculette from './pages/Calculette.page'
import RGPD from './pages/RGPD.page'
import NotFoundPage from './pages/404.page'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const router = createBrowserRouter([
  {
    path: '*',
    element: <NotFoundPage />,
  },
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/calculette',
    element: <Calculette />,
  },
  {
    path: '/mentions-legales-rgpd',
    element: <RGPD />,
  },
])

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    fr: {
      translation: frTranslation,
    },
    de: {
      translation: deTranslation,
    },
    nl: {
      translation: nlTranslation,
    },
    es: {
      translation: spTranslation,
    },
  },
  fallbackLng: localStorage.getItem('i18nextLng') || 'fr',

  interpolation: {
    escapeValue: false,
  },
})

if (localStorage.getItem('i18nextLng') === null) {
  localStorage.setItem('i18nextLng', i18n.language)
}

root.render(<RouterProvider router={router} />)
