import { Card } from 'flowbite-react'
import React from 'react'
import './accueil.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, A11y, Autoplay } from 'swiper/modules'

export default function Accueil3Component() {
  const { t } = useTranslation()

  return (
    <div className="mx-10 sm:w-3/5 sm:mx-auto my-20">
      <div className="mb-10 flex flex-col ">
        <h1 className="text-5xl text-left font-bold text-amber-500">{t('accueil3_title_1')}</h1>
        <h2 className="mt-10 text-left  mx-auto">{t('accueil3_description')}</h2>
      </div>
      <Swiper
        modules={[Navigation, A11y, Autoplay]}
        spaceBetween={50}
        slidesPerView={3}
        loop
        autoplay={{
          delay: 2500,
        }}
        navigation
        pagination={{ clickable: true }}
        breakpoints={{
          // Lorsque la largeur de la fenêtre est >= 640px
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // Lorsque la largeur de la fenêtre est >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          // Lorsque la largeur de la fenêtre est >= 1024px
          1024: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
      >
        <SwiperSlide>
          <Card className="bg-stone-900 text-white border-none min-w-56 sm:max-w-72">
            <h2 className="font-bold  text-center">{t('accueil3_card_1_title')}</h2>
            <p className="text-gray-500">{t('accueil3_card_1_description')}</p>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card className="bg-cyan-700 text-white border-none min-w-56 sm:max-w-72">
            <h2 className="font-bold  text-center">{t('accueil3_card_2_title')}</h2>
            <p className="text-amber-500">{t('accueil3_card_2_description')}</p>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card className="bg-amber-500 text-white border-none min-w-56 sm:max-w-72">
            <h2 className="font-bold  text-center">{t('accueil3_card_3_title')}</h2>
            <p className="text-cyan-700">{t('accueil3_card_3_description')}</p>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card className="bg-stone-900 text-white border-none min-w-56 sm:max-w-72">
            <h2 className="font-bold  text-center">{t('accueil4_card_4_title')}</h2>
            <p className="text-gray-500">{t('accueil3_card_4_description')}</p>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card className="bg-cyan-700 text-white border-none min-w-56 sm:max-w-72">
            <h2 className="font-bold  text-center">{t('accueil3_card_5_title')}</h2>
            <p className="text-amber-500">{t('accueil3_card_5_description')}</p>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card className="bg-amber-500 text-white border-none min-w-56 sm:max-w-72">
            <h2 className="font-bold  text-center">{t('accueil3_card_6_title')}</h2>
            <p className="text-cyan-700">{t('accueil3_card_6_description')}</p>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card className="bg-stone-900 text-white border-none min-w-56 sm:max-w-72">
            <h2 className="font-bold  text-center">{t('accueil3_card_7_title')}</h2>
            <p className="text-gray-500">{t('accueil3_card_7_description')}</p>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card className="bg-cyan-700 text-white border-none min-w-56 sm:max-w-72">
            <h2 className="font-bold  text-center">{t('accueil3_card_8_title')}</h2>
            <p className="text-amber-500">{t('accueil3_card_8_description')}</p>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card className="bg-stone-900 text-white border-none min-w-56 sm:max-w-72">
            <h2 className="font-bold text-center">{t('accueil3_card_9_title')}</h2>
            <p className="text-gray-500">{t('accueil3_card_9_description')}</p>
          </Card>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}
