import React, { useState } from 'react'
import './Financement.css'
import { useTranslation } from 'react-i18next'
import { Modal } from 'flowbite-react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, A11y, Autoplay } from 'swiper/modules'

export default function FinancementComponent() {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState({ title: '', imageUrl: '', description: '' })

  const icons = [
    { title: 'financement_projet_title_1', icon: './images/usine.png', description: 'financement_projet_title_1', imageUrl: './images/usine.png' },
    { title: 'financement_projet_title_2', icon: './images/ligne_de_tri.png', description: 'financement_projet_title_2', imageUrl: './images/ligne_de_tri.png' },
    { title: 'financement_projet_title_3', icon: './images/flotte.png', description: 'financement_projet_title_3', imageUrl: './images/flotte.png' },
    { title: 'financement_projet_title_4', icon: './images/eolien.png', description: 'financement_projet_title_4', imageUrl: './images/eolien.png' },
    { title: 'financement_projet_title_5', icon: './images/photovoltaique.png', description: 'financement_projet_title_5', imageUrl: './images/photovoltaique.png' },
    { title: 'financement_projet_title_6', icon: './images/agri.png', description: 'financement_projet_title_6', imageUrl: './images/agri.png' },
    { title: 'financement_projet_title_7', icon: './images/ambulance.png', description: 'financement_projet_title_7', imageUrl: './images/ambulance.png' },
    { title: 'financement_projet_title_8', icon: './images/bac_compost.png', description: 'financement_projet_title_8', imageUrl: './images/bac_compost.png' },
    { title: 'financement_projet_title_9', icon: './images/blanchisserie.png', description: 'financement_projet_title_9', imageUrl: './images/blanchisserie.png' },
    { title: 'financement_projet_title_10', icon: './images/blocbeton.png', description: 'financement_projet_title_10', imageUrl: './images/blocbeton.png' },
    { title: 'financement_projet_title_11', icon: './images/brh_godet.png', description: 'financement_projet_title_11', imageUrl: './images/brh_godet.png' },
    { title: 'financement_projet_title_12', icon: './images/bus.png', description: 'financement_projet_title_12', imageUrl: './images/bus.png' },
    { title: 'financement_projet_title_13', icon: './images/clim.png', description: 'financement_projet_title_13', imageUrl: './images/clim.png' },
    { title: 'financement_projet_title_14', icon: './images/compteur.png', description: 'financement_projet_title_14', imageUrl: './images/compteur.png' },
    { title: 'financement_projet_title_15', icon: './images/copeiru.png', description: 'financement_projet_title_15', imageUrl: './images/copeiru.png' },
    { title: 'financement_projet_title_16', icon: './images/crible.png', description: 'financement_projet_title_16', imageUrl: './images/crible.png' },
    { title: 'financement_projet_title_18', icon: './images/defibrilateur.png', description: 'financement_projet_title_18', imageUrl: './images/defibrilateur.png' },
    { title: 'financement_projet_title_19', icon: './images/dentaire.png', description: 'financement_projet_title_19', imageUrl: './images/dentaire.png' },
    {
      title: 'financement_projet_title_20',
      icon: './images/pharmacie.png',
      description: 'financement_projet_title_20',
      imageUrl: './images/pharmacie.png',
    },
    { title: 'financement_projet_title_21', icon: './images/ecran.png', description: 'financement_projet_title_21', imageUrl: './images/ecran.png' },
    { title: 'financement_projet_title_22', icon: './images/GRUE.png', description: 'financement_projet_title_22', imageUrl: './images/GRUE.png' },
    { title: 'financement_projet_title_23', icon: './images/impression.png', description: 'financement_projet_title_23', imageUrl: './images/impression.png' },
    { title: 'financement_projet_title_24', icon: './images/IT.png', description: 'financement_projet_title_24', imageUrl: './images/IT.png' },
    { title: 'financement_projet_title_25', icon: './images/logiciel.png', description: 'financement_projet_title_25', imageUrl: './images/logiciel.png' },
    { title: 'financement_projet_title_26', icon: './images/material_atelier.png', description: 'financement_projet_title_26', imageUrl: './images/material_atelier.png' },
    { title: 'financement_projet_title_27', icon: './images/materiel_fitness.png', description: 'financement_projet_title_27', imageUrl: './images/materiel_fitness.png' },
    { title: 'financement_projet_title_28', icon: './images/flotte.png', description: 'financement_projet_title_28', imageUrl: './images/flotte.png' },
    { title: 'financement_projet_title_30', icon: './images/nucleaire.png', description: 'financement_projet_title_30', imageUrl: './images/nucleaire.png' },
    { title: 'financement_projet_title_31', icon: './images/pc.png', description: 'financement_projet_title_31', imageUrl: './images/pc.png' },
    { title: 'financement_projet_title_32', icon: './images/pelle.png', description: 'financement_projet_title_32', imageUrl: './images/pelle.png' },
    { title: 'financement_projet_title_33', icon: './images/relamping.png', description: 'financement_projet_title_33', imageUrl: './images/relamping.png' },
    { title: 'financement_projet_title_34', icon: './images/scooter.png', description: 'financement_projet_title_34', imageUrl: './images/scooter.png' },
    { title: 'financement_projet_title_35', icon: './images/securite.png', description: 'financement_projet_title_35', imageUrl: './images/securite.png' },
    { title: 'financement_projet_title_36', icon: './images/smartphone.png', description: 'financement_projet_title_36', imageUrl: './images/smartphone.png' },
    { title: 'financement_projet_title_37', icon: './images/specialite.png', description: 'financement_projet_title_37', imageUrl: './images/specialite.png' },
    { title: 'financement_projet_title_38', icon: './images/taxi.png', description: 'financement_projet_title_38', imageUrl: './images/taxi.png' },
    { title: 'financement_projet_title_39', icon: './images/telecom.png', description: 'financement_projet_title_39', imageUrl: './images/telecom.png' },
    { title: 'financement_projet_title_40', icon: './images/toupie.png', description: 'financement_projet_title_40', imageUrl: './images/toupie.png' },
    { title: 'financement_projet_title_41', icon: './images/tp_caion.png', description: 'financement_projet_title_41', imageUrl: './images/tp_caion.png' },
    { title: 'financement_projet_title_42', icon: './images/tp.png', description: 'financement_projet_title_42', imageUrl: './images/tp.png' },
    { title: 'financement_projet_title_43', icon: './images/tpe.png', description: 'financement_projet_title_43', imageUrl: './images/tpe.png' },
    { title: 'financement_projet_title_44', icon: './images/VI.png', description: 'financement_projet_title_44', imageUrl: './images/VI.png' },
    { title: 'financement_projet_title_45', icon: './images/vigne.png', description: 'financement_projet_title_45', imageUrl: './images/vigne.png' },
    { title: 'financement_projet_title_46', icon: './images/VP_DIRECTION.png', description: 'financement_projet_title_46', imageUrl: './images/VP_DIRECTION.png' },
    { title: 'financement_projet_title_47', icon: './images/vul.png', description: 'financement_projet_title_47', imageUrl: './images/vul.png' },
  ]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const openModal = (icon: any) => {
    setModalContent(icon)
    setShowModal(true)
  }

  return (
    <div className="mt-20 section-financement bg-cyan-700 w-100 overflow-hidden" id="nos-solutions">
      <div>
        <div className="">
          <div className="flex flex-row flex-wrap sm:justify-center">
            <h1 className="font-bold text-amber-500 p-1 text-6xl">{t('financement_nos')}</h1>
            <h1 className="text-gray-50 p-1 text-6xl">{t('financement_financements_de_projets')}</h1>
            <h1 className="font-bold text-amber-500 p-1 text-6xl">{t('financement_pour')}</h1>
          </div>
          <div className="flex flex-row justify-center">
            <h1 className="font-bold text-amber-500 p-1 text-6xl">{t('financement_repondre_a_vos_besoins')}</h1>
          </div>
        </div>
        <div className="container flex justify-center sm:justify-between mx-auto p-10">
          <Swiper
            modules={[Navigation, A11y, Autoplay]}
            spaceBetween={50}
            slidesPerView={3}
            navigation
            loop
            autoplay={{
              delay: 2500,
            }}
            pagination={{ clickable: true }}
            breakpoints={{
              // Lorsque la largeur de la fenêtre est >= 640px
              0: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              // Lorsque la largeur de la fenêtre est >= 768px
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              // Lorsque la largeur de la fenêtre est >= 1024px
              1024: {
                slidesPerView: 8,
                spaceBetween: 40,
              },
            }}
          >
            {icons.map(icon => (
              <SwiperSlide>
                <div className="flex flex-col" role="button" tabIndex={0} onKeyDown={event => event.key === 'Enter' && openModal(icon)} onClick={() => openModal(icon)}>
                  <div className="flex justify-center">
                    <img src={icon.icon} alt="" className="h-16 w-16 cursor-pointer" />
                  </div>
                  <h3 className="text-center font-bold text-white">{t(icon.title)}</h3>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {showModal && (
          <Modal show={showModal} onClose={() => setShowModal(false)} className="pt-56 sm:pt-0 z-50">
            <Modal.Header>{t(modalContent.title)}</Modal.Header>
            <Modal.Body>
              <div className="text-center">
                <img src={modalContent.imageUrl} alt="Modal" className="mb-4 w-64 h-64 object-cover object-center mx-auto" />
                <p>{t(modalContent.description)}</p>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </div>
  )
}
