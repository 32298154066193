import React, { useEffect } from 'react'

import './Calculette.css'

import { NavbarComponent } from '../components/Navbar.component'
import FooterComponent from '../components/Footer.component'

export default function Calculette() {
  useEffect(() => {
    ;(window as any).CourtisiaSimulator('#app', '0851-13281503-6230-3932')
  }, [])

  return (
    <div>
      <NavbarComponent />
      <div id="app" className="pt-44" />
      <FooterComponent />
    </div>
  )
}
