import React from 'react'
import FooterComponent from '../components/Footer.component'
import { NavbarComponent } from '../components/Navbar.component'
import ContactComponent from '../components/Contact.component'
import AccueilComponent from '../components/Accueil.component'
import FinancementComponent from '../components/Financements-projets'
import Accueil3Component from '../components/Accueil3.component'
import GoingPartenaire from '../components/GoingPartenaire.component'
import ParticulierComponent from '../components/Particulier.component'
import ProComponent from '../components/Professionnels.component'
import Accueil1Component from '../components/Accueil1.component'

export default function HomePage() {
  return (
    <div className="overflow-x-hidden">
      <NavbarComponent />
      <AccueilComponent />
      <Accueil1Component />
      <Accueil3Component />
      <GoingPartenaire />
      <ProComponent />
      <FinancementComponent />
      <ParticulierComponent />
      <ContactComponent />
      <FooterComponent />
    </div>
  )
}
