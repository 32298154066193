import React, { useEffect, useRef } from 'react'
import { Button, Card } from 'flowbite-react'
import { useTranslation } from 'react-i18next'
import './goingpartenaire.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, A11y, Autoplay } from 'swiper/modules'

export default function GoingPartenaire() {
  const { t } = useTranslation()

  const cards = [
    <SwiperSlide>
      <Card key="1" className="bg-cyan-700 text-white border-none min-w-56 sm:max-w-72 ">
        <img src="./images/Recyclage.png" alt="s" className="h-36 w-36 mx-auto" />
        <div>
          <p className="font-bold text-center">{t('goingPartenaire_partenaire_nom_1')}</p>
          <p>{t('goingPartenaire_partenaire_role_1')}</p>
        </div>
      </Card>
    </SwiperSlide>,
    <SwiperSlide>
      <Card key="2" className="bg-cyan-700 text-white border-none min-w-56 sm:max-w-72">
        <img src="./images/transport.png" alt="s" className="h-36 w-36 mx-auto" />
        <p className="font-bold center">{t('goingPartenaire_partenaire_nom_2')}</p>
        <p>{t('goingPartenaire_partenaire_role_2')}</p>
      </Card>
    </SwiperSlide>,
    <SwiperSlide>
      <Card key="3" className="bg-cyan-700 text-white border-none min-w-56 sm:max-w-72">
        <img src="./images/medical.png" alt="s" className="h-36 w-36 mx-auto" />
        <p className="font-bold text-center">{t('goingPartenaire_partenaire_nom_3')}</p>
        <p>{t('goingPartenaire_partenaire_role_3')}</p>
      </Card>
    </SwiperSlide>,
    <SwiperSlide>
      <Card key="4" className="bg-cyan-700 text-white border-none min-w-56 sm:max-w-72">
        <img src="./images/travauxpublic.png" alt="s" className="h-36 w-36 mx-auto" />
        <p className="font-bold text-center">{t('goingPartenaire_partenaire_nom_4')}</p>
        <p>{t('goingPartenaire_partenaire_role_4')}</p>
      </Card>
    </SwiperSlide>,
    <SwiperSlide>
      <Card key="5" className="bg-cyan-700 text-white border-none min-w-56 sm:max-w-72">
        <img src="./images/ESS.png" alt="s" className="h-36 w-36 mx-auto" />
        <p className="font-bold text-center">{t('goingPartenaire_partenaire_nom_5')}</p>
        <p>{t('goingPartenaire_partenaire_role_5')}</p>
      </Card>
    </SwiperSlide>,
    <SwiperSlide>
      <Card key="6" className="bg-cyan-700 text-white border-none min-w-56 sm:max-w-72">
        <img src="./images/Industrie.png" alt="s" className="h-36 w-36 mx-auto" />
        <p className="font-bold text-center">{t('goingPartenaire_partenaire_nom_6')}</p>
        <p>{t('goingPartenaire_partenaire_role_6')}</p>
      </Card>
    </SwiperSlide>,
    <SwiperSlide>
      <Card key="7" className="bg-cyan-700 text-white border-none min-w-56 sm:max-w-72">
        <img src="./images/viticole.png" alt="s" className="h-36 w-36 mx-auto" />
        <p className="font-bold text-center">{t('goingPartenaire_partenaire_nom_7')}</p>
        <p>{t('goingPartenaire_partenaire_role_7')}</p>
      </Card>
    </SwiperSlide>,
    <SwiperSlide>
      <Card key="8" className="bg-cyan-700 text-white border-none min-w-56 sm:max-w-72">
        <img src="./images/CHR.png" alt="s" className="h-36 w-36 mx-auto" />
        <p className="font-bold text-center">{t('goingPartenaire_partenaire_nom_8')}</p>
        <p>{t('goingPartenaire_partenaire_role_8')}</p>
      </Card>
    </SwiperSlide>,
    <SwiperSlide>
      <Card key="12" className="bg-cyan-700 text-white border-none min-w-56 sm:max-w-72">
        <img src="./images/collectivite.png" alt="s" className="h-36 w-36 mx-auto" />
        <p className="font-bold text-center">{t('goingPartenaire_partenaire_nom_12')}</p>
        <p>{t('goingPartenaire_partenaire_role_12')}</p>
      </Card>
    </SwiperSlide>,
  ]

  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const interval = setInterval(() => {
      if (scrollRef.current) {
        const { scrollLeft, clientWidth, scrollWidth } = scrollRef.current
        const isAtEnd = scrollLeft + clientWidth >= scrollWidth

        if (isAtEnd) {
          scrollRef.current.scrollTo({ left: 0, behavior: 'smooth' })
        } else {
          scrollRef.current.scrollTo({ left: scrollLeft + 305, behavior: 'smooth' })
        }
      }
    }, 3000) // Ajustez ce temps comme nécessaire

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="mx-10 sm:w-3/5 sm:mx-auto my-20" id="goigpartenaire">
      <Button color="light" pill>
        {t('goingPartenaire_devenir_partenaire')}
      </Button>
      <p className="text-5xl font-bold text-amber-500 mt-5">
        {t('goingPartenaire_projet_succes_intro')}
        {t('goingPartenaire_projet_succes_highlight')}
        {t('goingPartenaire_projet_succes_outro')}
      </p>
      <h2 className="mt-10 mb-10 text-center mx-auto">{t('goingPartenaire_lorem_ipsum')}</h2>

      <Swiper
        modules={[Navigation, A11y, Autoplay]}
        spaceBetween={50}
        slidesPerView={3}
        navigation
        loop
        autoplay={{
          delay: 2500,
        }}
        pagination={{ clickable: true }}
        breakpoints={{
          // Lorsque la largeur de la fenêtre est >= 640px
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // Lorsque la largeur de la fenêtre est >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          // Lorsque la largeur de la fenêtre est >= 1024px
          1024: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
      >
        {cards}
      </Swiper>
    </div>
  )
}
