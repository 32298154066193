import React from 'react'
import './Financement.css'
import { useTranslation } from 'react-i18next'

export default function Accueil1Component() {
  const { t } = useTranslation()

  return (
    <div className="section-financement bg-cyan-700 w-screen overflow-hidden" id="a-propos-de-nous">
      <div className="flex-col gap-5 mt-10 sm:w-3/5 mx-auto">
        <div className="">
          <div className="flex flex-row justify-center ">
            <h1 className="font-bold text-amber-500 p-1 text-5xl ">{t('accueil_titre_1')}</h1>
          </div>
          <div className="flex flex-row justify-center ">
            <h1 className=" text-gray-50 p-1 text-5xl">{t('accueil_titre_2')}</h1>
          </div>
          <div className="flex flex-row justify-center sm:text-center ">
            <h1 className="font-bold text-amber-500 p-1 text-5xl">{t('accueil_titre_3')}</h1>
          </div>
        </div>
        <div className="mx-auto flex flex-wrap gap-5 justify-center text-justify">
          <p className=" text-gray-50 p-1 mx-auto">{t('accueil_description_1')}</p>
        </div>
        <div className="flex flex-col sm:flex-row justify-center sm:justify-between mt-10">
          <div className="flex flex-col mt-4">
            <h1 className="flex justify-center font-bold text-white text-2xl text-nowrap">{t('accueil_investis_montant')}</h1>
            <h3 className="text-center text-white text-nowrap">{t('accueil_investis')}</h3>
          </div>
          <div className="flex flex-col mt-4">
            <h1 className="flex justify-center font-bold text-white text-2xl text-nowrap">{t('accueil_note')}</h1>
            <h3 className="text-center text-white">{t('accueil_note_google_avis')}</h3>
          </div>
          <div className="flex flex-col mt-4">
            <h1 className="flex justify-center font-bold text-white text-2xl text-nowrap">{t('accueil_clients')}</h1>
            <h3 className="text-center text-white">{t('accueil_clients_satisfaits')}</h3>
          </div>
        </div>
      </div>
    </div>
  )
}
