import React from 'react'

import './Calculette.css'

import { NavbarComponent } from '../components/Navbar.component'
import FooterComponent from '../components/Footer.component'

export default function RGPD() {
  return (
    <div>
      <NavbarComponent />
      <div className="flex flex-col gap-5 p-5 text-justify">
        <p className="pt-44 text-center">
          Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l’économie numérique, il est précisé aux utilisateurs du site
          lecomptoirdufinancement.fr l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
        </p>
        <h1 className=" font-bold text-xl">● Edition du site</h1>
        <p>Le Comptoir du Financement peut être contacté aux coordonnées suivantes :</p>
        <p>Adresse postale :</p>
        <p>Le Comptoir du Financement</p>
        <p>17 rue des Anglais</p>
        <p>62930 Wimereux</p>
        <p>Adresse électronique:</p>
        <p>contact@comptoir-fi.fr</p>
        <h1 className=" font-bold text-xl">● Hébergeur</h1>
        <p>
          Le site lecomptoirdufinancement.fr est hébergé par la société par actions simplifiée OVH immatriculée au R.C.S. Lille Métropole sous le numéro 424 761 419 dont le siège
          social est situé au 2 rue Kellermann, 59100 Roubaix. L’hébergeur peut être contacté aux coordonnées disponibles sur le lien suivant : http://www.ovh.com/fr/support/
        </p>
        <h1 className=" font-bold text-xl">● Utilisation de la marque Le Comptoir du Financement et des contenus du site lecomptoirdufinancement.fr</h1>
        <p>
          Le Comptoir du Financement est une marque déposée, enregistrée auprès de l’Institut national de la propriété intellectuelle sous le numéro (en cours). Une publication aux
          bulletins officiels de la propriété intellectuelle de ce dépôt a été régulièrement effectuée. L’utilisation de la marque Le Comptoir du Financement est ainsi strictement
          limitée à son déposant, à savoir la SAS Le Comptoir du Financement et aux personnes autorisées par cette dernière. Toutes les marques, photographies, textes, articles,
          commentaires, illustrations, images animées ou non, séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire
          fonctionner le site et plus généralement tous les éléments reproduits ou utilisés sur le site sont protégés par les lois en vigueur au titre de la propriété
          intellectuelle. Ils sont la propriété pleine et entière du Comptoir du Financement ou de ses partenaires, sauf mentions particulières. Toute reproduction, représentation,
          utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques, sans l’accord préalable et écrit
          du Comptoir du Financement, sont strictement interdites. Le fait pour Le Comptoir du Financement de ne pas engager de procédure dès la prise de connaissance de ces
          utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites. Seule l’utilisation pour un usage privé dans un cercle de
          famille est autorisée et toute autre utilisation est constitutive de contrefaçon et/ou d’atteinte aux droits voisins, sanctionnées par Code de la propriété
          intellectuelle. La reprise de tout ou partie de ce contenu nécessite l’autorisation préalable de Le Comptoir du Financement ou du titulaire des droits sur ce contenu.
        </p>
        <h1 className=" font-bold text-xl">● Liens hypertextes</h1>
        <p>
          Le site peut contenir des liens hypertexte donnant accès à d’autres sites web édités et gérés par des tiers et non par Le Comptoir du Financement. Le Comptoir du
          Financement ne pourra être tenu responsable directement ou indirectement dans le cas où lesdits sites tiers ne respecteraient pas les dispositions légales. La création de
          liens hypertexte vers le site ne peut être faite qu’avec l’autorisation écrite et préalable de Le Comptoir du Financement.
        </p>
        <h1 className=" font-bold text-xl p-5 text-center">NOTRE POLITIQUE DE CONFIDENTIALITÉ</h1>
        <h1 className=" font-bold text-xl">● Données Personnelles</h1>
        <p> (date d’effet le 29/05/2024)</p>
        <h1 className=" font-bold text-xl">● Notre engagement</h1>
        <p>
          La SAS Le Comptoir du Financement est consciente de l’importance que revêt le respect de la confidentialité aux yeux de ses clients. C’est pourquoi, nous veillons à faire
          preuve de la plus grande clarté concernant nos modalités de collecte, d’utilisation, de divulgation, de transfert et de stockage de vos informations personnelles. La
          présente Politique de confidentialité s’applique à l’ensemble des méthodes et process, sites Web, plateformes e-learning , logiciel métier et autres applications en ligne
          qui la citent ou comportent un lien vers celle-ci. Elle s’applique indépendamment du fait que vous utilisiez un ordinateur, un téléphone mobile, une tablette, une
          télévision ou un autre appareil intelligent pour accéder à nos services. Vous trouverez ci-dessous un résumé des messages clés contenus dans notre Politique de
          Confidentialité. Pour de plus amples informations sur la manière dont nous traitons vos informations, veuillez poursuivre la lecture ci-dessous
        </p>
        <h1 className=" font-bold text-xl">● Informations que nous collectons</h1>
        <p>Nous collectons divers types d’informations dans le cadre des services, notamment :</p>
        <p>• Des informations que vous nous fournissez directement;</p>
        <p>• Des informations que nous recueillons relatives à votre utilisation de nos services;</p>
        <p>• Des informations que nous obtenons de sources tierces.</p>
        <p>
          Nous pouvons également vous demander votre consentement pour collecter des informations ou vous avertir séparément des modalités de collecte de vos informations
          personnelles d’une manière qui n’est pas décrite dans la présente politique de confidentialité, tel que requis pour certains services supplémentaires.
        </p>
        <h1 className=" font-bold text-xl">● Utilisation et partage d’informations</h1>
        <p>Nous utilisons les informations que nous collectons notamment dans le but de :</p>
        <p>• Vous fournir les Services et programmes de formations demandés;</p>{' '}
        <p>• Comprendre la manière dont vous utilisez les Services du Comptoir du Financement afin de mieux vous satisfaire;</p>
        <p>• Mieux comprendre nos clients et prospects de façon à leur proposer les communications, les services et les expériences les plus adaptés; </p>
        <p>• Proposer des publicités et des contenus personnalisés avec votre consentement si nécessaire.</p>
        <h1 className=" font-bold text-xl">Il se peut que nous partagions vos informations avec :</h1>
        <p>• Des sociétés affiliées, c’est-à-dire des sociétés apparentées à Le Comptoir du Financement, en cela qu’elles sont détenues ou contrôlées par la même entité.</p>
        <p>
          • Des partenaires commerciaux, c’est-à-dire des entreprises fiables susceptibles de fournir des informations sur les produits et services que vous pourriez apprécier (si
          vous avez donné votre consentement).
        </p>
        <p>• Des opérateurs, c’est-à-dire des sociétés qui fournissent des services pour le compte ou au nom de Le Comptoir du Financement.</p>
        <p>• Les autorités policières, lorsque nous sommes tenus de le faire ou pour protéger Le Comptoir du Financement et ses utilisateurs.</p>
        <h1 className=" font-bold text-xl">● Informations supplémentaires au sujet de produits et services spécifiques</h1>
        <p>
          Bien que la présente Politique de confidentialité s’applique à tous nos Services, elle prévoit également certains suppléments qui apportent des compléments d’information
          sur nos pratiques en matière de confidentialité relatives à des Services spécifiques, si cela s’avère nécessaire. Ces suppléments s’appliquent à votre utilisation des
          Services correspondants.
        </p>
        <h1 className=" font-bold text-xl">● Politique de confidentialité globale de Le Comptoir du Financement</h1>
        <p>
          Bien que la présente Politique de confidentialité s’applique à tous nos Services, elle prévoit également certains suppléments qui apportent des compléments d’information
          sur nos pratiques en matière de confidentialité relatives à des Services spécifiques, si cela s’avère nécessaire. Ces suppléments s’appliquent à votre utilisation des
          Services correspondants. Il est important que vous lisiez attentivement la Politique de confidentialité et toute politique supplémentaire, parce que ces documents
          fournissent des informations sur la façon dont vos données personnelles sont traitées chaque fois que vous utilisez nos Services. Il est également important que vous
          vérifiiez régulièrement les mises à jour de la Politique de confidentialité. Si nous mettons à jour la Politique de confidentialité, nous vous ferons part des changements
          que nous considérons significatifs à l’avance en affichant une notification sur les Services pertinents ou par e-mail, selon le cas. La version la plus récente de la
          Politique de confidentialité sera toujours disponible ici. Vérifiez la “date d’effet” indiquée tout en haut de cette page pour savoir à quand remonte la dernière mise à
          jour de la Politique de confidentialité.
        </p>
        <h1 className=" font-bold text-xl">● Protection de vos informations</h1>
        <p>
          Nous prenons très au sérieux la protection de vos données et nous avons mis en place les mesures physiques et techniques appropriées pour protéger les informations que
          nous collectons dans le cadre des Services. Il faut toutefois savoir qu’aucun site Web ni système informatique et aucune transmission Internet ni connexion sans fil n’est
          totalement sûr.
        </p>
        <h1 className=" font-bold text-xl">● Vos droits</h1>
        <p>
          Vous avez le droit de demander des détails au sujet des informations que nous collectons et de faire corriger toute inexactitude présente dans celles-ci, de contester ou
          demander la limitation du traitement, et de demander le droit d’accès, de suppression ou la portabilité de vos informations. En vertu des lois de certaines juridictions,
          nous nous réservons le droit de décliner toute demande qui serait déraisonnable par son caractère répétitif, nécessiterait des efforts techniques disproportionnés,
          porterait atteinte à la confidentialité de tiers, serait extrêmement difficile à satisfaire ou concernerait des informations auxquelles l’accès n’est pas exigé par la
          législation locale. Si vous souhaitez introduire une demande d’accès à vos informations, veuillez consulter le site
          https://www.lecomptoirdufinancement.fr/mentions-legales-rgpd/ ou nous contacter à l’adresse contact@comptoir-fi.fr Si vous demandez la suppression d’informations
          personnelles, vous reconnaissez que vous ne pourrez peut-être pas accéder aux Services ou les utiliser et que des informations personnelles demeureront dans les
          enregistrements et archives du Comptoir du Financement pendant un certain temps, conformément à la loi applicable, mais Le Comptoir du Financement n’utilisera pas ces
          informations à des fins commerciales.
        </p>
        <h1 className=" font-bold text-xl">● Conservation des données</h1>
        <p>
          {' '}
          Nous ne conserverons vos données personnelles que pendant la durée nécessaire aux fins de la collecte. Cela signifie que les données seront détruites ou supprimées de nos
          systèmes lorsqu’elles ne seront plus nécessaires. Nous prenons les mesures appropriées pour veiller à ce que les informations vous concernant soient traitées et
          conservées en fonction de la logique suivante :
        </p>
        <p>au moins la durée pour laquelle les informations sont utilisées pour vous fournir un service;</p>
        <p>comme l’exigent la loi, un contrat, ou conformément à nos obligations légales;</p>
        <p>
          le temps nécessaire à leur traitement uniquement, ou plus longtemps si la loi applicable l’exige ou si cela est nécessaire en vertu d’un quelconque contrat ou à des fins
          statistiques, sous réserve des garanties appropriées.
        </p>
      </div>
      <FooterComponent />
    </div>
  )
}
