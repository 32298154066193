import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Navbar } from 'flowbite-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './Navbar.css'
import { useNavigate } from 'react-router-dom'

export const NavbarComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/calculette')
  }

  return (
    <>
      <div className="top-banner">
        <button type="button" onClick={handleClick} className="fixed sm:relative" style={{ zIndex: 1010 }}>
          <img src="./images/logo.png" className="mr-3 h-16 sm:h-40 index" alt="Flowbite React Logo" />
        </button>
      </div>
      <Navbar fluid rounded className="fixed-navbar">
        <Navbar.Brand onClick={handleClick}>
          <div />
        </Navbar.Brand>
        <div className="flex md:order-2 gap-2">
          <Button outline className="font-bold" href="/#goigpartenaire">
            {t('navbar_partenire')}
          </Button>

          <Button href="/#contact">
            <FontAwesomeIcon icon={faEnvelope} />
          </Button>

          <Navbar.Toggle />
        </div>
        <Navbar.Collapse>
          <Navbar.Link href="/#Accueil" className="font-bold">
            {t('navbar_accueil')}
          </Navbar.Link>
          <Navbar.Link href="/#nos-solutions" className="font-bold">
            {t('navbar_solutions')}
          </Navbar.Link>
          <Navbar.Link href="/#a-propos-de-nous" className="font-bold">
            {t('navbar_propos_nous')}
          </Navbar.Link>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}
