import React from 'react'
import { Button } from 'flowbite-react'
import { useTranslation } from 'react-i18next'

export default function ParticulierComponent() {
  const { t } = useTranslation()

  return (
    <div className="m-10 xl:w-3/5 xl:mx-auto my-20">
      <Button color="gray" pill>
        {t('particulier_title_button')}
      </Button>
      <div className="flex justify-center sm:justify-between mx-auto flex-wrap sm:flex-nowrap">
        <div className="text-content p-4 ">
          <h1 className="text-3xl font-bold text-amber-500 mt-4">{t('particulier_title')}</h1>
          <p className="mt-4 sm:max-w-96">{t('particulier_description')}</p>
        </div>
        <div className="image-content ">
          <img src="./images/illustration_accueil4.png" alt="Intérieur" className="border-8 rounded-lg border-black" />
        </div>
      </div>
    </div>
  )
}
