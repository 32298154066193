import { Button, Card } from 'flowbite-react'
import React from 'react'
import './accueil.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowTurnUp } from '@fortawesome/free-solid-svg-icons'

import { useTranslation } from 'react-i18next'

export default function AccueilComponent() {
  const { t } = useTranslation()

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 overflow-x-hidden pt-20 sm:pt-20" id="Accueil">
      <div className="m-10 flex flex-col">
        <h1 className="text-5xl font-bold text-amber-500">{t('accueil_title_1')} </h1>
        <h1 className="text-5xl font-bold text-amber-500">{t('accueil_title_2')} </h1>
        <h1 className="text-5xl italic text-cyan-700">{t('accueil_title_3')} </h1>
        <h1 className="text-5xl font-bold text-amber-500">{t('accueil_title_4')} </h1>
        <p className="mt-10 max-w-96">{t('accueil_description')}</p>
        <Button className="mt-10 sm:w-2/3" href="#nos-solutions">
          <div className="mr-4">{t('accueil_boutton_title')}</div>
          <FontAwesomeIcon icon={faArrowTurnUp} />
        </Button>
      </div>
      <div className="m-10 ">
        <div className="card-container">
          <Card className="card bg-stone-900 h-96 text-white border-none">
            <img src="./images/illustration_accueil3.png" className="h-96" alt="placeholder" />
          </Card>
          <Card className="card bg-stone-900 text-white border-none">
            <img src="./images/illustration_accueil.png" className="h-52" alt="placeholder" />
            <h2 className="font-bold">{t('accueil_card2_title_1')}</h2>
            <p className="text-gray-500">{t('accueil_card2_description_1')}</p>
            <h2 className="font-bold">{t('accueil_card2_description_2')}</h2>
            <h2 className="text-amber-500 text-xl mt-0">{t('accueil_card2_description_3')}</h2>
          </Card>
          <Card className="card bg-stone-900 text-white border-none">
            <img src="./images/illustration_accueil2.png" className="h-52" alt="placeholder" />
          </Card>
        </div>
      </div>
    </div>
  )
}
