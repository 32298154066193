import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Footer, Modal } from 'flowbite-react'
import { useNavigate } from 'react-router-dom'

export default function FooterComponent() {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState({ title: '', info: '', nom: '', adresse: '' })
  const navigate = useNavigate()

  const adresses = [
    { title: 'Hauts de France', info: 'footer_adresse_1_numero', nom: 'footer_adresse_1_nom', adresse: 'footer_adresse_1_adresse' },
    { title: 'Ile de France', info: 'footer_adresse_2_numero', nom: 'footer_adresse_2_nom', adresse: 'footer_adresse_2_adresse' },
    { title: 'Normandie', info: 'footer_adresse_3_numero', nom: 'footer_adresse_3_nom', adresse: 'footer_adresse_3_adresse' },
    { title: 'Grand Est', info: 'footer_adresse_4_numero', nom: 'footer_adresse_4_nom', adresse: 'footer_adresse_4_adresse' },
    { title: 'Paca', info: 'footer_adresse_5_numero', nom: 'footer_adresse_5_nom', adresse: 'footer_adresse_5_adresse' },
    { title: 'Nouvelle Aquitaine', info: 'footer_adresse_6_numero', nom: 'footer_adresse_6_nom', adresse: 'footer_adresse_6_adresse' },
    { title: 'Bourgogne Rhones-Alpes', info: 'footer_adresse_7_numero', nom: 'footer_adresse_7_nom', adresse: 'footer_adresse_7_adresse' },
  ]

  const openModal = (adresses: any) => {
    setModalContent(adresses)
    setShowModal(true)
  }
  const handleClick = () => {
    navigate('/mentions-legales-rgpd')
  }

  return (
    <Footer>
      <div className="w-full" id="footer">
        <hr className="my-12 h-0.5 border-t-0 bg-neutral-200 m-6" />
        <div className="grid w-full grid-cols-1 gap-6 px-6 py-8 md:grid-cols-3 justify-center">
          <div>
            <Footer.Title className="text-cyan-700 font-bold" title={t('footer_categorie_1')} />
            <Footer.LinkGroup col>
              <Footer.Link href="#footer">{t('footer_categorie_1_description_1')}</Footer.Link>
              <Footer.Link href="#footer">{t('footer_categorie_1_description_2')}</Footer.Link>
              <Footer.Link href="#footer">{t('footer_categorie_1_description_3')}</Footer.Link>
              <Footer.Link href="#footer">{t('footer_categorie_1_description_4')}</Footer.Link>
              <Footer.Link href="#footer">{t('footer_categorie_1_description_5')}</Footer.Link>
              <Footer.Link href="#footer">{t('footer_categorie_1_description_6')}</Footer.Link>
              <Footer.Link href="#footer">{t('footer_categorie_1_description_7')}</Footer.Link>
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title className="text-cyan-700 font-bold" title={t('footer_categorie_2')} />
            <Footer.LinkGroup col>
              <Footer.Link href="#">
                <button type="button" onClick={handleClick}>
                  {t('footer_categorie_2_description_1')}
                </button>
              </Footer.Link>
              <Footer.Link href="#">
                <button type="button" onClick={handleClick}>
                  {t('footer_categorie_2_description_2')}
                </button>
              </Footer.Link>
              <Footer.Link href="/#contact">{t('footer_categorie_2_description_7')}</Footer.Link>
            </Footer.LinkGroup>
          </div>
          <div>
            <Footer.Title className="text-cyan-700 font-bold" title={t('footer_categorie_3')} />
            <Footer.LinkGroup col>
              {adresses.map(adr => (
                <Footer.Link href="#footer" onClick={() => openModal(adr)}>
                  {adr.title}
                </Footer.Link>
              ))}
            </Footer.LinkGroup>
          </div>
        </div>
        <div className="w-full px-4 py-6">
          <Footer.Copyright href="#footer" by={t('footer_description')} year={2022} />
        </div>
      </div>
      {showModal && (
        <Modal show={showModal} onClose={() => setShowModal(false)} className="pt-56 sm:pt-0 z-50">
          <Modal.Header>{modalContent.title}</Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <h1>Numero : {t(modalContent.info)}</h1>
              <h1>{t(modalContent.adresse)}</h1>
              <h1>{t(modalContent.nom)}</h1>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </Footer>
  )
}
