import { Button } from 'flowbite-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function ProComponent() {
  const { t } = useTranslation()

  return (
    <div className="mx-10 xl:w-3/5 xl:mx-auto my-20">
      <Button color="gray" pill>
        {t('pro_title_button')}
      </Button>

      <div className="flex flex-col sm:flex-row justify-center gap-10">
        <div className="image-content sm:w-2/3 pt-5 my-auto">
          <img src="./images/pourlespro.webp" alt="Intérieur" className="border-8 rounded-lg border-black" />
        </div>
        <div className="text-content">
          <h1 className="text-3xl font-bold text-amber-500 mt-4">{t('pro_title')}</h1>
          <p className="mt-4 sm:max-w-96">{t('pro_title_description')}</p>
        </div>
      </div>
    </div>
  )
}
