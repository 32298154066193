import * as React from 'react'
import './404.page.css'
import { NavbarComponent } from '../components/Navbar.component'

function NotFoundPage() {
  return (
    <div className="h-screen">
      <NavbarComponent />
      <div className="notfound-page-content">
        <h1 className="uppercase text-3xl font-extrabold text-cyan-700 ">404 Not Found</h1>
      </div>
    </div>
  )
}

export default NotFoundPage
