import React, { useState } from 'react'
import { Textarea, Button, TextInput, Alert } from 'flowbite-react' // Ajout de Alert pour les notifications
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapPin, faPhone } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

const ContactComponent = () => {
  const { t } = useTranslation()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [notification, setNotification] = useState<{ type: string; message: string }>() // État pour les notifications

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()

    try {
      await axios
        .get('/sendMail.php', {
          params: {
            firstname: firstName,
            lastname: lastName,
            email,
            phone,
            message,
          },
        })
        .then(response => {
          if (response.data === 'error') {
            setNotification({
              type: 'error',
              message: `Erreur lors de l'envoi du message : si vous avez envoyé plusieurs messages rapidement, veuillez patienter quelques minutes.`,
            })
          } else {
            setNotification({ type: 'success', message: 'Message envoyé avec succès !' })
            // Réinitialiser les champs du formulaire
            setFirstName('')
            setLastName('')
            setEmail('')
            setPhone('')
            setMessage('')
          }
        })
    } catch (error) {
      setNotification({ type: 'error', message: `Erreur lors de l'envoi du message : si vous avez envoyé plusieurs messages rapidement, veuillez patienter quelques minutes.` })
      console.error("Erreur lors de l'envoi du formulaire:", error)
    }
  }

  return (
    <div className="p-5 text-white contact-container flex flex-col xl:flex-row xl:w-3/5 mx-auto" id="contact">
      {/* Partie Carte */}
      <div className=" bg-stone-900 xl:w-5/12 rounded-t-xl xl:rounded-xl">
        <div className="map-container">
          <img src="./images/carte.jpg" alt="Intérieur" />
        </div>
        <div className="contact-info p-4">
          <div className="flex items-center mb-2">
            <FontAwesomeIcon icon={faMapPin} className="mr-2 text-amber-500" /> <span className="text-amber-500 font-bold">{t('contact_adresse')}</span>
          </div>
          <div className="flex items-center mb-2">
            <FontAwesomeIcon icon={faPhone} className="mr-2" /> <span>{t('contact_telephone')}</span>
          </div>
          <div className="flex items-center">
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" /> <span>{t('contact_email')}</span>
          </div>
        </div>
      </div>

      {/* Partie Formulaire */}
      <div className="xl:w-7/12 bg-stone-900 p-5 h-1/2 my-auto rounded-b-xl xl:rounded-none xl:rounded-r-xl">
        <h2 className="text-4xl font-bold text-amber-500 mb-2">{t('contact_rejoignez_nous')}</h2>
        <p className="mb-4">{t('contact_description')}</p>
        {notification && (
          <Alert color={notification.type === 'success' ? 'success' : 'failure'} className="mb-4">
            {notification.message}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <div className="flex gap-4 mb-4 w-100">
            <TextInput id="firstName" type="text" placeholder={t('contact_prenom')} className="flex-grow" value={firstName} onChange={e => setFirstName(e.target.value)} />
            <TextInput id="lastName" type="text" placeholder={t('contact_nom')} className="flex-grow" value={lastName} onChange={e => setLastName(e.target.value)} />
          </div>
          <div className="flex gap-4 mb-4">
            <TextInput id="email" type="email" placeholder={t('contact_email_placeholder')} className="flex-grow" value={email} onChange={e => setEmail(e.target.value)} />
            <TextInput id="phone" type="tel" placeholder={t('contact_telephone_placeholder')} className="flex-grow" value={phone} onChange={e => setPhone(e.target.value)} />
          </div>
          <Textarea id="message" placeholder={t('contact_message')} value={message} onChange={e => setMessage(e.target.value)} />
          <Button type="submit" className="mt-5">
            {t('contact_envoyez')}
          </Button>
        </form>
      </div>
    </div>
  )
}

export default ContactComponent
